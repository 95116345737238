<template>
	<v-toolbar v-if="!conditionalDisplay.embedded" fixed app clipped-left>
		<NavBarSmartphone v-if="navSideBar" :modules="modules" :navigation-counter="navigationCounter" />
		<w-layout v-if="$vuetify.breakpoint.smAndDown" align-center fill-height justify-center row title>
			<v-btn v-if="showBackButton" icon flat @click="goBack()">
				<v-icon color="primary" class="outlined" x-large>arrow_back</v-icon>
			</v-btn>
			<w-flex v-else mr-2 shrink>
				<em class="fa fa-fw">&nbsp;</em>
			</w-flex>
			<w-flex class="pointer" fill-height shrink>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-img
							:src="logo"
							:alt="$t('logo')"
							contain
							class="pointer"
							width="100"
							max-width="100%"
							height="100%"
							max-height="100%"
							shrink
							v-on="on"
							@click="goTo('home')"
						/>
					</template>
					<span v-t="'home.name'" />
				</v-tooltip>
			</w-flex>
			<w-flex ml-3 shrink text-truncate v-text="pageTitle" />
			<w-spacer />
			<v-spacer />
			<v-toolbar-side-icon v-if="$vuetify.breakpoint.smAndDown" color="primary" @click="showNavSideBar()" />
		</w-layout>
		<w-layout v-else align-center fill-height justify-start row title>
			<w-flex v-if="showBackButton" class="pointer" shrink @click="goBack()">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-icon color="primary" class="outlined" x-large v-on="on">arrow_back_ios</v-icon>
					</template>
					<span v-t="'actions.go_back'"></span>
				</v-tooltip>
			</w-flex>
			<w-flex v-else mr-2 shrink>
				<em class="fa fa-fw">&nbsp;</em>
			</w-flex>
			<w-flex class="pointer" fill-height ml-2 shrink>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-img
							:src="logo"
							:alt="$t('logo')"
							contain
							class="pointer"
							width="100"
							max-width="100%"
							height="100%"
							max-height="100%"
							shrink
							v-on="on"
							@click="goTo('home')"
						/>
					</template>
					<span v-t="'home.name'" />
				</v-tooltip>
			</w-flex>
			<w-flex v-if="accountingFirms && accountingFirms.length > 1" ml-2 row shrink>
				<v-menu ref="menu" :nudge-width="100" offset-y max-height="90%">
					<template v-slot:activator="{ on }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: onTooltip }">
								<v-toolbar-title class="pointer" fill-height v-on="{ ...on, ...onTooltip }">
									<span>{{ selectedAccountingFirm ? selectedAccountingFirm.name : '' }}</span>
									<v-icon color="primary">arrow_drop_down</v-icon>
								</v-toolbar-title>
							</template>
							<span v-t="'home.accounting_firm_selection'" />
						</v-tooltip>
					</template>
					<w-list>
						<w-list-tile v-for="item in accountingFirms" :key="item.id" @click="selectAccountingFirm(item)">
							<w-list-tile-title :class="item === selectedAccountingFirm ? 'primary--text' : ''" title v-text="item.name" />
						</w-list-tile>
					</w-list>
				</v-menu>
			</w-flex>
			<w-flex v-if="selectedAccountingFirm && (!accountingFirms || accountingFirms.length <= 1)" mx-3 shrink>{{ selectedAccountingFirm.name }}</w-flex>
			<w-divider v-if="!isAccountant" vertical />
			<w-flex v-if="!isAccountant && companies && companies.length > 1" mx-3 shrink>
				<v-menu :nudge-width="100" offset-y class="overflow-y-auto" max-height="90%">
					<template v-slot:activator="{ on }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: onTooltip }">
								<v-toolbar-title class="pointer" fill-height v-on="{ ...on, ...onTooltip }">
									<span>{{ selectedCompany ? selectedCompany.company : '' }}</span>
									<v-icon color="primary">arrow_drop_down</v-icon>
								</v-toolbar-title>
							</template>
							<span v-t="'home.company_selection'" />
						</v-tooltip>
					</template>
					<w-list>
						<w-list-tile v-for="item in companies" :key="item.id" @click="selectCompany(item)">
							<w-list-tile-title v-text="item.company"></w-list-tile-title>
						</w-list-tile>
					</w-list>
				</v-menu>
			</w-flex>
			<w-flex v-if="!isAccountant && selectedCompany && (!companies || companies.length <= 1)" mx-3 shrink>{{ selectedCompany.company }}</w-flex>
			<w-divider vertical />
			<w-flex ml-3 shrink text-truncate v-text="pageTitle" />
		</w-layout>

		<w-spacer v-if="$vuetify.breakpoint.mdAndUp"></w-spacer>
		<v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp" class="align-center">
			<w-flex order-md0>
					<w-btn v-if="isSuperAdmin" color="red" flat icon="security" large mini @click="goTo('sa-home')">
						{{ $t('super_admin.navbar_button_tooltip') }}
					</w-btn>
					<v-tooltip v-for="module in orderedModules" :key="module.id" bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon @click="goTo(module.name)" v-on="on">
								<v-icon color="primary">{{ module.icon }}</v-icon>
							</v-btn>
						</template>
						<span v-text="module.title" />
					</v-tooltip>
				</w-flex>
				<w-flex order-md1>
					<v-btn icon large @click="goTo(isAccountant ? 'accounting-firm-settings' : 'company-settings')">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon color="secondary" large v-on="on">settings</v-icon>
							</template>
							<span v-t="'settings.name'"></span>
						</v-tooltip>
					</v-btn>

					<SupportChatButton v-if="!conditionalDisplay.isMobileApp && appService.hasModule('support')" toolbar />
				</w-flex>
				<w-flex align-center d-flex justify-center shrink order-md3>
					<UserMenu />
					<HeadWay />
				</w-flex>

		</v-toolbar-items>
	</v-toolbar>
</template>

<script>
import { mapState } from 'vuex'
import PageTitleMixin from '@/mixins/PageTitle'
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'NavBar',
	components: {
		HeadWay: () => ({
			component: import('@/components/Navigation/HeadWay')
		}),
		NavBarSmartphone: () => ({
			component: import('@/components/Navigation/NavBarSmartphone')
		}),
		UserMenu: () => ({
			component: import('@/components/Navigation/UserMenu')
		}),
		SupportChatButton: () => ({
			component: import('@/components/Support/SupportChatButton')
		})
	},
	mixins: [AppModuleGuard, PageTitleMixin],
	data: function () {
		return {
			navigationCounter: 0,
			navSideBar: false
		}
	},
	computed: {
		...mapState({
			logo: state => state.whitelabel.selected.logo,
			isSuperAdmin: state => state.auth.isSuperAdmin,
			isAccountant: state => state.auth.isAccountant,
			accountingFirms: state => state.accountingFirm.list,
			selectedAccountingFirm: state => state.accountingFirm.selected,
			companies: state => state.company.list,
			selectedCompany: state => state.company.selected,
			userTitle: state => state.user.title,
			userFirstname: state => state.user.firstname,
			userLastname: state => state.user.lastname,
			modules: state => state.modules.navbar
		}),
		showBackButton: function () {
			return this.navigationCounter > 0
		},
		orderedModules: function () {
			return [...this.modules].sort((m1, m2) => {
				if (m1.priority < m2.priority) {
					return -1
				} else if (m1.priority > m2.priority) {
					return 1
				}
				return 0
			})
		}
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.GO_BACK, action: this.goBack },
				{ event: this.appEvents.HIDE_NAVSIDEBAR, action: this.hideNavSideBar },
				{ event: this.appEvents.INCREMENT_NAVIGATION_COUNTER, action: this.incrementNavigationCounter },
				{ event: this.appEvents.ACCOUNTING_FIRM_CREATED, action: this.addAccountingFirm },
				{ event: this.appEvents.ACCOUNTING_FIRM_DELETED, action: this.removeAccountingFirm },
				{ event: this.appEvents.ACCOUNTING_FIRM_UPDATED, action: this.updateAccountingFirm },
				{ event: this.appEvents.CONTEXT_MENU, action: this.closeMenu }
			]
		},
		addAccountingFirm: function (accountingFirm) {
			return this.appService.addAccountingFirm(accountingFirm)
		},
		incrementNavigationCounter: function () {
			this.navigationCounter++
		},
		goBack: function () {
			this.navigationCounter--
			this.$router.go(-1)
		},
		removeAccountingFirm: function (accountingFirm) {
			return this.appService.removeAccountingFirm(accountingFirm)
		},
		updateAccountingFirm: function (accountingFirm) {
			return this.appService.updateAccountingFirm(accountingFirm)
		},
		goTo: function (routePath) {
			this.appService.goTo(routePath)
		},
		selectAccountingFirm: function (accountingFirm) {
			if (this.selectedAccountingFirm.domain === accountingFirm.domain) {
				this.appService.selectAccountingFirm(accountingFirm.id)
			} else {
				localStorage.removeItem('lastSelectedAccountingFirmId')
				this.appService.redirectToAccountingFirmWorkspace(accountingFirm)
			}
		},
		selectCompany: function (company) {
			this.appService.selectVendor(company.id)
		},
		showNavSideBar: function () {
			this.navSideBar = true
		},
		hideNavSideBar: function () {
			this.navSideBar = false
		},
		closeMenu: function () {
			this.$refs.menu?.save();
		},
		checkAccessModule: function () {
			return this.appService.hasModule('notification')
		}
	}
}
</script>
